<template>
	<div class="row">
		<div class="col-12">
			<div class="d-flex justify-content-end align-items-end mb-3">
				<b-button @click="syncRevenueToVehicles()" v-if="userHasPermission('trips007')"
					>Re-Sync revenue for partners vehicles</b-button
					>
			</div>
			<div class="card">
				<div class="card-header">
					<div class="d-flex justify-content-between align-items-center">
						<div class="">
							<label for="status_active" class="d-block-inline px-2">
								<input
									type="radio"
									v-model="statusFilter"
									name="status"
									value="active"
									id="status_active"
									/>
								Active
							</label>
							<label for="status_inactive" class="d-block-inline px-2">
								<input
									type="radio"
									v-model="statusFilter"
									name="status"
									value="inactive"
									id="status_inactive"
									/>
								Inactive
							</label>
						</div>
						<div class="">
							<download-button
								v-if="tableData.length"
								:downloading="downloadingReport"
								label="Download Report"
								@download="downloadReport()"
								></download-button>
						</div>
					</div>
				</div>
				<b-table
					striped
					hover
					selectable
					responsive
					show-empty
					:items="tableData"
					:fields="fields"
					:current-page="currentPage"
					:busy="loading"
					>
					<template #table-busy>
						<div
							class="d-flex flex-column justify-content-center align-items-center"
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>

					<template #empty>
						<p class="text-center text-secondary">No records available</p>
					</template>

					<template #cell(serialNumber)="data">
						<p>
							{{ data.index + 1 }}
						</p>
					</template>

					<template #cell(vehicle)="data">
						<div class="d-flex align-items-center">
							<div class="avatar avatar-xs bus-avatar">
								<img
									src="@/assets/img/gray_bus.svg"
									class="avatar-img"
									alt="Bus"
									/>
							</div>
							<p class="ml-2 mt-2 mb-2">
								<router-link
									class="sh-link"
									:to="{
										name: 'ShowVehicle',
										params: { vehicleId: data.item.id },
									}"
									>
									{{ data.item.brand }} {{ data.item.name }}
								</router-link>
							</p>
						</div>
					</template>

					<template #cell(plate_number)="data">
						{{ data.item.registration_number }}
					</template>
					<template #cell(capacity)="data">
						{{ data.item.seats }}
					</template>
					<template #cell(type)="data">
						{{ data.item.type }}
					</template>

					<template #cell(driver)="data">
						<router-link
							class="sh-link"
							v-if="data?.item?.driver?.fname || data?.item?.driver?.lname"
							:to="{
								name: 'ShowDriver',
								params: { driverId: data?.item?.driver?.id },
							}"
							>
							{{ `${data?.item?.driver?.lname} ${data?.item?.driver?.fname}` }}
						</router-link>
						<template v-else>
							{{ 'No driver assigned' }}
						</template>
					</template>
					<template #cell(date)="data">
						{{ data?.item?.created_at | date('MMM dd, yyyy') }}
					</template>

					<template #cell(status)="data">
						<span
							:class="`badge p-2 ${
								data.item.status === 'active' ? 'badge-success' : 'badge-danger'
							}`"
							>
							{{ data.item.status === 'active' ? 'Active' : 'Inactive' }}
						</span>
					</template>

					<template #cell(actions)="data">
						<b-dropdown
							size="sm"
							variant="link"
							class="position-relative text-center w-100"
							:toggle-class="'text-decoration-none'"
							:no-caret="true"
							>
							<template #button-content>
								<div class="w-100 text-center">
									<i class="fe fe-more-horizontal"></i>
								</div>
							</template>
							<b-dropdown-item @click.prevent.stop="viewDetails(data.item)">
								View details
							</b-dropdown-item>
							<b-dropdown-item
								v-if="userHasPermission('partners012')"
								link-class="text-danger"
								@click.prevent.stop="unAssignVehicle(data.item)"
								>
								Unassign vehicle
							</b-dropdown-item>
						</b-dropdown>
					</template>
				</b-table>
				<div class="card-footer" v-if="totalRecords">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-dark font-weight-bold">{{
								tablePageText
							}}</span>
						</div>
						<div class="col-auto">
							<b-pagination
								v-model="currentPage"
								:total-rows="totalRecords"
								:per-page="pageSize"
								></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<delete-prompt-modal
			modal-title="Are you absolutely sure ?"
			@close="showUnlinkVehicleModal = false"
			:verifying-delete="false"
			verification-message="It’s important you read the message below!"
			:processing-action="unlinkingVehicle"
			:show="showUnlinkVehicleModal"
			:show-prompt-key-label="true"
			prompt-text="Un-assigning a vehicle from a vehicle partner will delete the vehicle’s record from the partner’s vehicle list."
			prompt-key="KEY"
			:delete-function="
				() => {
					unlinkVehicle()
				}
			"
			>
		</delete-prompt-modal>

		<b-modal
			id="revenueDateRangePickerModal"
			centered
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
			hide-footer
			hide-header
			>
			<main class="d-flex flex-column">
				<div>
					<p class="mb-3">Select revenue generation date range:</p>
					<v-datepicker
						style="width: 100%"
						class="float-left"
						v-model="filter.range"
						placeholder="Filter by date"
						range
						></v-datepicker>
				</div>
				<div class="d-flex justify-content-end align-items-center mb-3 pt-4">
					<button
						style="width: 30%"
						class="mr-3 btn btn-secondary"
						@click="closeRevenueDateModal"
						>
						Close
					</button>
					<button
						:class="[
							dateFilterValid || vehicleRevenueLinkingState
								? 'btn-primary'
								: 'btn-secondary',
							'btn',
						]"
						:disabled="!dateFilterValid || vehicleRevenueLinkingState"
						style="width: 30%"
						@click="proceedSyncRevenueToVehicles()"
						>
						{{ vehicleRevenueLinkingState ? 'processing...' : 'Proceed' }}
					</button>
				</div>
			</main>
		</b-modal>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import tableView from '@/mixins/tableview'
import DeletePromptModal from '@/components/modals/DeletePromptModal'
import { extractErrorMessage } from '@/utils/helpers'
import { ExportToCsv } from 'export-to-csv'
import DownloadButton from '@/components/core/DownloadButton.vue'
import moment from 'moment'

export default {
  name: 'PartnerVehicles',
  mixins: [tableView],
  inject: ['partnerPageData'],
  components: { DeletePromptModal, DownloadButton },
  props: {
    partnerId: {
      type: [Number, String],
      required: true
    }
  },
  watch: {
    currentPage () {
      this.fetchPartnerVehicles(false)
    },
    statusFilter () {
      this.fetchPartnerVehicles(true)
    }
  },
  data () {
    return {
      statusFilter: 'active',
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      loading: false,
      errorLoading: false,
      tableData: [],
      fields: [
        {
          key: 'serialNumber',
          label: 'S/N'
        },
        {
          key: 'vehicle',
          label: 'Vehicle'
        },
        {
          key: 'plate_number',
          label: 'Plate Number'
        },
        {
          key: 'capacity',
          label: 'Capacity'
        },
        {
          key: 'type',
          label: 'Type'
        },
        {
          key: 'driver',
          label: 'Driver'
        },
        {
          key: 'date',
          label: 'Date Added'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      showUnlinkVehicleModal: false,
      unlinkingVehicle: false,
      downloadingReport: false,
      vehicleRevenueLinkingState: false,
      filter: {
        range: []
      }
    }
  },
  created () {
    this.fetchPartnerVehicles()
  },
  mounted () {
    this.partnerPageData().registerChangeListener(() => {
      this.fetchPartnerVehicles(true)
    })
  },
  methods: {
    downloadReport () {
      this.downloadingReport = true
      const csvData = this.tableData.map((data) => {
        return {
          vehicle: data?.brand + ' ' + data?.name,
          plateNumber: data?.registration_number,
          capacity: data?.seats,
          type: data.type,
          driver: data?.driver?.fname + ' ' + data?.driver?.lname,
          date_joined: data?.created_at,
          status: data?.status === 'active' ? 'Active' : 'Inactive'
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        filename: 'Vehicle List',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Vehicle List',
        useTextFile: false,
        useBom: true,
        headers: [
          'Vehicle',
          'PlateNumber',
          'Capacity',
          'Type',
          'Driver',
          'Date Joined',
          'Status'
        ]
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)
      this.downloadingReport = false
      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true
      })
    },
    fetchPartnerVehicles (resetView = true) {
      if (resetView) {
        this.loading = true
        this.currentPage = 1
      }
      const params = {
        metadata: true,
        limit: this.pageSize,
        page: this.currentPage,
        related: 'driver',
        status: this.statusFilter
      }
      this.axios
        .get(`/v1/partner/${this.partnerId}/vehicles`, { params })
        .then((res) => {
          this.tableData = res.data.data
          this.totalRecords = res.data.metadata?.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    viewDetails (data) {
      this.$router.push({
        name: 'ShowVehicle',
        params: { vehicleId: data.id }
      })
    },
    unAssignVehicle (data) {
      this.highlightVehicle = data
      this.showUnlinkVehicleModal = true
    },
    closeUnlinkModal () {
      this.highlightVehicle = null
      this.showUnlinkVehicleModal = false
    },
    unlinkVehicle () {
      this.unlinkingVehicle = true
      // TODO: refactor with use of vehicle-partner.service class
      this.axios
        .patch(`/v1/vehicles/${this.highlightVehicle.id}`, {
          partner_id: null
        })
        .then(() => {
          this.fetchPartnerVehicles(true)
          this.closeUnlinkModal()
          this.$swal({
            icon: 'success',
            title: 'Vehicle Un-assigned',
            text: 'Vehicle has been un-assigned successfully',
            showCloseButton: true
          })
          this.unlinkingVehicle = false
        })
        .catch((error) => {
          this.unlinkingVehicle = false
          const msg = extractErrorMessage(
            error,
            'An error occurred, please try again.'
          )
          this.$swal().showValidationMessage(msg)
        })
    },
    async syncRevenueToVehicles () {
      this.$bvModal.show('revenueDateRangePickerModal')
    },
    async proceedSyncRevenueToVehicles () {
      this.vehicleRevenueLinkingState = true
      if (this.dateFilterValid) {
        const vehiclesIds = this.tableData.map((eachVehicle) => eachVehicle.id)

        const payload = {
          partner_id: Number(this.partnerId),
          vehicle_ids: vehiclesIds,
          start_date: this.dateRange[0],
          end_date: this.dateRange[1]
        }
        await this.axios
          .post('/v1/trips/revenues', payload)
          .then(() => {
            this.filter.range = []
            this.fetchPartnerVehicles()
            this.$toastr.s(
              'Re-Sync revenue for partners was successful',
              'Success'
            )
          })
          .catch((error) => {
            this.$toastr.e(error.response.data.message, 'Error')
          })
          .finally(() => {
            this.vehicleRevenueLinkingState = false
            this.$bvModal.hide('revenueDateRangePickerModal')
          })
      }
    },
    closeRevenueDateModal () {
      this.$bvModal.hide('revenueDateRangePickerModal')
      this.filter.range = []
    }
  },
  computed: {
    dateRange () {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },
    dateFilterValid () {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/b-table';
</style>
